import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import Table from "react-bootstrap/Table";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/info/bod-executives.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const BodExecutives = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7484af5091ddcb5e9cd3b"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="BOD Executives" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="BOD Executives"
            />
            <Container style={{ paddingTop: "20px", paddingBottom: "60px" }}>
                {jsonData.map((par, j) => {
                    return (
                        <p key={j} style={{ marginBottom: "0px" }}>
                            <a
                                style={{ color: "#000", fontSize: "20px" }}
                                href={par.link}
                                target="_top"
                                download
                            >
                                {par.title}
                            </a>
                        </p>
                    );
                })}
            </Container>
        </Layout>
    );
};
BodExecutives.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query BodExecutivesQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default BodExecutives;
